import React, { useContext } from "react";

// Import Images
import bg1 from "../../images/main-banner/bg1.jpg";
// import doctorImg from "../../images/main-banner/doctor.png";
import ptImg1 from "../../images/shap/triangle-orange.png";
import ptImg2 from "../../images/shap/square-blue.png";
import ptImg3 from "../../images/shap/chicle-blue-2.png";
import ptImg4 from "../../images/shap/plus-orange.png";
import ptImg5 from "../../images/shap/wave-orange.png";
import grunePraxis from "../../images/grune-praxis.png";

import logo from "../../images/logo-7.png";
import { Context } from "../../context";

import useWindowDimensions from "../../useWindowDimensions";
// import BookingButton from "./BookingButton";
import BookingButton2 from "./BookingButton2";

const AboutSection = () => {
  const { selectedFlow, setSelectedFlow } = useContext(Context);
  const { width } = useWindowDimensions();

  const isOrtho = selectedFlow === "ortho";

  const headingText = isOrtho ? (
    <h1
      style={{
        fontSize: "48px",
        textAlign: "center",
      }}
    >
      Und die{" "}
      {width < 768 ? (
        <>
          <br />
          kiefer-
          <br />
          orthopädische
          <br />
        </>
      ) : (
        "kieferorthopädische"
      )}{" "}
      Behandlung vergeht wie im Flug
    </h1>
  ) : (
    <h1
      style={{
        fontSize: "48px",
        textAlign: "center",
      }}
    >
      Auf Urlaube freut sich jedes Kind.
      <br />
      <br />
      Bei uns auch auf den zahnärztlichen Besuch!
    </h1>
  );

  return (
    <>
      <div
        className="main-banner"
        style={{ backgroundImage: "url(" + bg1 + ")" }}
      >
        <div className="container inner-content">
          <div className="row align-items-center justify-space-between">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="banner-img">
                <img
                  style={{
                    width: "40vw",
                    maxWidth: "480px",
                    margin: "0 auto",
                    marginBottom: "20px",
                    display: "block",
                  }}
                  src={logo}
                  alt=""
                  onClick={() => {
                    setSelectedFlow(null);
                  }}
                />
              </div>
            </div>

            <div className="col-lg-7 col-md-6 col-sm-12">
              {/* <img src={logo} /> */}

              {headingText}
              <BookingButton2
                classes="btn-primary btn-md shadow mx-auto d-block mt-60 mb-30"
                style={{ maxWidth: "250px" }}
              />
              {/* <BookingButton2
                classes="btn-secondary btn-lg shadow mx-auto d-block mt-60"
                style={{ maxWidth: "250px" }}
              /> */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              width="320px"
              style={{
                // position: "absolute",
                alignSelf: "center",
                cursor: "pointer",
                // bottom: "0",
              }}
              src={grunePraxis}
              onClick={() => {
                window.open("https://www.gruene-praxis.de/", "_blank");
              }}
            />
          </div>
        </div>
        <img className="pt-img1 animate1" src={ptImg1} alt="" />
        <img className="pt-img2 animate2" src={ptImg2} alt="" />
        <img className="pt-img3 animate3" src={ptImg3} alt="" />
        <img className="pt-img4 animate4" src={ptImg4} alt="" />
        <img className="pt-img5 animate-wave" src={ptImg5} alt="" />
      </div>
    </>
  );
};
export default AboutSection;
