import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
// import pic1 from "../../images/about/pic-1.jpg";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
import animateRotate from "../../images/shap/plus-blue.png";
import BookingButton2 from "../elements/BookingButton2";
import PageTitle from "../elements/PageTitle";

const API_KEY = "AIzaSyCfnZuSOuD_HdpLH6oHS7AVno-DPaTw-kI";
const DESTINATION = "Jasperallee 36, 38102 Braunschweig, Germany";

const ORIGIN_1 = "52.26992574971127, 10.546021457809417";
const ORIGIN_2 = "52.268206612000746, 10.54014969353163";
const ORIGIN_3 = "52.27449507303577, 10.540058457770721";

const ContactUs = () => {
  const map1 = `https://www.google.com/maps/embed/v1/directions?key=${API_KEY}&origin=${ORIGIN_1}&destination=${DESTINATION}&mode=walking`;
  const map2 = `https://www.google.com/maps/embed/v1/directions?key=${API_KEY}&origin=${ORIGIN_2}&destination=${DESTINATION}&mode=walking`;
  const map3 = `https://www.google.com/maps/embed/v1/directions?key=${API_KEY}&origin=${ORIGIN_3}&destination=${DESTINATION}&mode=walking`;

  return (
    <React.Fragment>
      <PageTitle
        title="Kontakt"
        canonical="kontakt"
        description="Zum neuen Lächeln geht es in nur einem Klick!"
      />
      <div className="page-content contacts bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner banner-lg contact-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Kontakt</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Kontakt
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={animateWave} alt="" />
            <img className="pt-img2 animate2" src={animate2} alt="" />
            <img
              className="pt-img3 animate-rotate"
              src={animateRotate}
              alt=""
            />
          </div>
        </div>
        <section className="section-area section-sp1">
          <div className="container contacts__main">
            <h3>Zum neuen Lächeln geht es in nur einem Klick!</h3>
            <BookingButton2
              classes="btn-primary btn-md shadow mx-auto d-block mt-30"
              style={{ maxWidth: "300px" }}
            />
          </div>
        </section>
        <div className="contacts__working-hours container">
          <h3 className="text-center">Persönliche Sprechzeiten</h3>
          <table className="table table-bordered mt-30">
            {/* <thead>
              <tr>
                <th>Tag</th>
                <th>Uhrzeit</th>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td style={{ width: "60%" }}>
                  <strong>Montag bis Donnerstag</strong>
                </td>
                <td>08.00 - 13.00 und 14.00 - 18.00 Uhr</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>
                  <strong>Freitag</strong>
                </td>
                <td>08:00 - 13:00 Uhr</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="contacts__contact-hours container">
          <h3 className="text-center">Telefonische Sprechzeiten</h3>
          <table className="table table-bordered mt-20">
            <tbody>
              <tr>
                <td style={{ width: "60%" }}>
                  <strong>Montag bis Donnerstag</strong>
                </td>
                <td>10.00 - 12.00 und 16.00 - 18.00 Uhr</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>
                  <strong>Freitag</strong>
                </td>
                <td>10.00 - 12.00 Uhr</td>
              </tr>
            </tbody>
          </table>

          <h6
            className="mt-30"
            style={{ textAlign: "left", width: "100%", textAlign: "justify" }}
          >
            Außerhalb dieser Zeiten und, wenn wir uns gerade um andere Personen
            kümmern, nimmt rund um die Uhr unser digitaler Assistent Aaron gern
            Ihren Anruf entgegen.
            <br />
            <br />
            Bei Fragen oder anderen Anliegen rufen wir Sie spätestens am
            nächsten Werktag zurück, insofern Sie Aaron Ihr Anliegen geschildert
            haben.
          </h6>
        </div>
        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div
                style={{
                  cursor: "pointer",
                }}
                className="col-lg-4 col-md-6 mb-30"
                onClick={() => window.open("tel:053122458888", "_self")}
              >
                <div className="feature-container feature-bx4 feature4">
                  <div className="icon-md feature-icon">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Telefon</h5>
                    <a href="tel:053122458888">0531 2245 8888</a>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  window.open("mailto:praxis@fliegender-zahn.de", "_self")
                }
                className="col-lg-4 col-md-6 mb-30"
              >
                <div className="feature-container feature-bx4 feature3">
                  <div className="icon-md feature-icon">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">E-Mail</h5>
                    <a href="mailto:praxis@fliegender-zahn.de">
                      praxis@fliegender-zahn.de
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature2">
                  <div className="icon-md feature-icon">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Adresse</h5>
                    <p>
                      Jasperallee 36
                      <br />
                      38102 Braunschweig
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="contacts__map mx-50 ">
            <div>
              <h4 className="mt-20">Standort</h4>
              <p>
                Sie finden uns in der Nähe der Innenstadt und zum Hauptbahnhof
                im östlichen Ringgebiet. Sie erreichen uns sehr gut mit dem Bus,
                mit dem Fahrrad oder zu Fuß.
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3600.224446063633!2d10.537690526991307!3d52.26940498935829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aff5cde0f27051%3A0xc574f041377808d!2sJasperallee%2036%2C%2038102%20Braunschweig%2C%20Germany!5e0!3m2!1sen!2sbg!4v1719401364307!5m2!1sen!2sbg"
                width="100%"
                height="600"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="klinik-map"
              ></iframe>
              <h4 className="mt-60">Anreise</h4>
              <p className="text-justify">
                <strong style={{ fontWeight: 600 }}>
                  Wir raten von einer Anreise mit dem Auto aufgrund der
                  schwierigen Parkplatzsituation in unserem Stadtteil ab.
                </strong>
                <br />
                <br />
                Für Fahrradfahrende haben wir in ausreichender Anzahl eigene
                Fahrradständer.
                <br />
                <br />
                Sollten Sie mit dem Taxi anreisen, bieten wir gern an, Ihnen
                eins zur Abreise zu rufen. Sprechen Sie hierzu einfach unsere
                Empfangspersonen an.
                <br />
                <br />
                Nachfolgend finden Sie die Wegbeschreibungen zu den nächsten
                Haltestellen des öffentlichen Nahverkehrs sowie unten den Link
                zur Fahrplanauskunft:
              </p>
              <div className="mt-30">
                <p style={{ fontWeight: 600 }}>
                  In direkter Nähe zu der Praxis, zwei Gehminuten entfernt, an
                  der Bushaltestelle „Stadtpark“ fahren in Abständen von weniger
                  Minuten Busse der Linie 418, 422 und 423 in unterschiedliche
                  Richtungen ab.
                </p>
                <iframe
                  src={map1}
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="map-2"
                ></iframe>
              </div>
              <div className="mt-30">
                <p style={{ fontWeight: 600 }}>
                  In vier Gehminuten entfernt, an der Bushaltestelle
                  „Jasperallee“ fahren in Abständen von wenigen Minuten Busse
                  der Linien 230, 416, 419 und 436 in weitere Richtungen ab.
                </p>
                <iframe
                  src={map2}
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="map-3"
                ></iframe>
              </div>
              <div className="mt-30">
                <p style={{ fontWeight: 600 }}>
                  Die nächstliegende Straßenbahnhaltestelle „Bindestraße“ ist
                  ungefähr zehn Gehminuten von der Praxis entfernt. Hier fährt
                  die Linie 3 in Abständen von fünf bis zehn Minuten ab.
                </p>
                <iframe
                  src={map3}
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="map-4"
                ></iframe>
              </div>
              <strong>
                <p
                  style={{
                    fontWeight: 600,
                  }}
                  className="mt-30"
                >
                  Die Fahrplanauskunft der Braunschweiger Verkehrsbetriebe
                  finden Sie{" "}
                  <span>
                    <u>
                      <a
                        href="https://www.bsvg.net/fahrplan/fahrplanauskunft.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        hier
                      </a>
                    </u>
                  </span>
                  .
                  <br />
                  Die Abfahrtszeiten der wichtigsten Linien werden bei uns zudem
                  vor Ort im Empfangsbereich angezeigt.
                </p>
              </strong>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
